import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import WbSunnyIcon from '@material-ui/icons/WbSunny'
import NightsStayOutlinedIcon from '@material-ui/icons/NightsStayOutlined'
import TwitterIcon from '@material-ui/icons/Twitter'
import GitHubIcon from '@material-ui/icons/GitHub'
import IconButton from '@material-ui/core/IconButton';

import { rhythm, scale } from "../utils/typography"

import './layout.css'

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header
    const themeSpanStyle = { position: 'relative' }

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={location.pathname === blogPath ? `/blog/` : `/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog/`}
          >
            {title}
          </Link>
        </h3>
      )
      themeSpanStyle.top = '-10px'
    }
    return (
      <Wrapper>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(31),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            backgroundColor: 'var(--bg)',
            color: 'var(--textNormal)',
            transition: 'color 0.2s ease-out, background 0.2s ease-out',
          }}
        >
          <header>
            {header}
            <span style={themeSpanStyle}>
              <ThemeToggler>
                {({ theme, toggleTheme }) => (
                  <IconButton className="theme-toggle"
                    onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}>
                    {theme === 'light' && <WbSunnyIcon style={{ color: 'var(--textNormal)' }} />}
                    {theme === 'dark' && <NightsStayOutlinedIcon style={{ color: 'var(--textNormal)' }} />}
                  </IconButton>
                )}
              </ThemeToggler>
            </span>
          </header>
          <main>{children}</main>
        </div>
        <Footer>
          <a className="social" href="https://twitter.com/dannysiu392005">
            <StyledTwitterIcon style={{ fontSize: '1.9rem' }} />
          </a>
          <a className="social" href="https://github.com/dannysiu392005">
            <StyledGitHubIcon style={{ fontSize: '1.9rem' }} />
          </a>
        </Footer>
      </Wrapper>
    )
  }
}

const StyledTwitterIcon = styled(TwitterIcon)`
  margin: 0px 5px;
  cursor: pointer;
  &:hover {
    color: #00ACEE;
  }
`

const StyledGitHubIcon = styled(GitHubIcon)`
  margin: 0px 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
